

export default class NavigatorTool {

    static redirect(path) {
        window.location.href = path;
    }

    


}