import React from "react";
import AuthController from "../Common/Security/AuthController";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import AppLogo from "../Common/Widget/Logo/AppLogo";
import NavigatorTool from "../Common/Utility/NavigatorTool";
import LoginController from "./LoginController";
import AuthApi from "../Common/Api/AuthApi";

import "./../Assets/Page/RegisterController.scss";
import AnonymousController from "../Common/Controller/AnonymousController";

export default class RegisterController extends AnonymousController {
    static _PATH = "/auth/register";

    constructor(props) {
        super(props)


        this.state = {
            loggedIn: false,
            showConfirmPassword: false,
            showPassword: false,
            passwordMatch: null,
            passwordStrength: 0,

            email: null,
            password: null,
            confirmPassword: null,
            firstName: null,
            lastName: null,

            emailError: false,
            passwordError: false,
            confirmPasswordError: false,
            firstNameError: false,
            lastNameError: false,
        }
    }


    componentDidMount() {
        this.checkLoginStatus();

    }

    async checkLoginStatus() {
        const isLoggedIn = await AuthController.isLoggedIn();
        if (isLoggedIn) {
            window.location.href = "/";
        }
    }

    registrationHandler = async () => {
        const errorCounts = this.validateFields();

        if (errorCounts === 0) {
            const result = await AuthApi.register({
                email: this.state.email,
                password: this.state.password,
                birthDay: new Date(document.querySelector('#birthDay').value).toISOString(),
                firstName: document.querySelector('#firstName').value,
                lastName: document.querySelector('#lastName').value
            });

            if (result.success === true) {
                // auto login step
                await AuthApi.login(this.state.email, this.state.password);

                // auto redirect step
                window.location.href = "/";
            }
        }



    }



    _onEmailKeyUp = (e) => {
        let email = e.target.value;
        this.setState({ email: email })
    }

    _onPasswordKeyUp = (e) => {
        let password = e.target.value;
        this.setState({ password: password });

        this._checkPasswordStrong(password);
    }

    _onConfirmPasswordKeyUp = (e) => {
        let password = e.target.value;

        this.setState({
            passwordMatch: this.state.password ? this.state.password === password : null,
            confirmPassword: password
        });
    }

    _checkPasswordStrong = (password) => {
        const length = password.split("").length;

        if (length === 0) {
            this.setState({ passwordStrength: null });
        } else if (length > 0 && length < 8) {
            this.setState({ passwordStrength: "weak" });
        } else if (length >= 8 && length <= 12) {
            this.setState({ passwordStrength: "medium" });
        } else if (length > 12) {
            this.setState({ passwordStrength: "strong" });
        }

    }

    /**
     * Event handler
     */
    togglePasswordDisplay = () => {
        this.setState({ showPassword: !this.state.showPassword });
    }


    /**
     * Event handler
     */
    toggleConfirmPasswordDisplay = () => {
        this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
    }

    validateFields = () => {
        let errorsCount = 0;

        if (document.querySelector('#firstName').value.split('').length === 0) {
            this.setState({ firstNameError: true });
            errorsCount += 1;
        }

        if (document.querySelector('#lastName').value.split('').length === 0) {
            this.setState({ lastNameError: true });
            errorsCount += 1;
        }

        if (document.querySelector('#birthDay').value.split('').length === 0) {
            this.setState({ birthDayError: true });
            errorsCount += 1;
        }

        if (document.querySelector('#email').value.split('').length === 0) {
            this.setState({ emailError: true });
            errorsCount += 1;
        }

        if (document.querySelector('#password').value.split('').length === 0) {
            this.setState({ passwordError: true });
            errorsCount += 1;
        }

        if (document.querySelector('#confirmPassword').value.split('').length === 0) {
            this.setState({ confirmPasswordError: true });
            errorsCount += 1;
        }

        return errorsCount;
    }

    render() {
        // render the login page 

        return super.render({

            inviewToRender: <div className="register-controller">

                <div className="form">
                    <AppLogo></AppLogo>

                    <div className="inputs">

                        <div className="user-credentials">
                            <input id="firstName" data-error={this.state.firstNameError} className="firstName" placeholder="Firstname" required />
                            {this.state.firstNameError === true ? <div className="error-message">Check your firstname</div> : ""}
                            <input id="lastName" data-error={this.state.lastNameError} className="lastName" placeholder="Lastname" required />
                            {this.state.firstNameError === true ? <div className="error-message">Check your lastname</div> : ""}
                            <input id="birthDay" data-error={this.state.birthDayError} className="birthDay" type="date" required />
                            {this.state.firstNameError === true ? <div className="error-message">Check your birthday</div> : ""}
                        </div>



                        <input id="email" onKeyUp={this._onEmailKeyUp} data-error={this.state.emailError} autoComplete="true" className="email" type="email" placeholder="Email" required />
                        {this.state.emailError === true ? <div className="error-message">Invalid password</div> : ""}

                        <div className="password-fields" data-error={this.state.passwordError} data-strength={this.state.passwordStrength}>
                            <input id="password" onKeyUp={this._onPasswordKeyUp} autoComplete="true" className="password" type={this.state.showPassword ? "text" : "password"} placeholder="Password" required />
                            <button onClick={this.togglePasswordDisplay} className="togglePasswordDisplay" data-active={this.state.showPassword}>
                                {this.state.showPassword === false ? <AiFillEye /> : <AiFillEyeInvisible />}
                            </button>

                        </div>
                        {this.state.passwordError === true ? <div className="error-message">Invalid password</div> : ""}


                        <div className="password-fields" data-error={this.state.confirmPasswordError} data-match={this.state.passwordMatch}>
                            <input id="confirmPassword" onKeyUp={this._onConfirmPasswordKeyUp} autoComplete="true" className="confirm-password" type={this.state.showConfirmPassword ? "text" : "password"} placeholder="Confirm password" required />
                            <button onClick={this.toggleConfirmPasswordDisplay} className="togglePasswordDisplay" data-active={this.state.showConfirmPassword}>
                                {this.state.showConfirmPassword === false ? <AiFillEye /> : <AiFillEyeInvisible />}
                            </button>

                        </div>
                        {this.state.confirmPasswordError === true ? <div className="error-message">Invalid password</div> : ""}


                        <div className="usefull-links">
                            <input type="checkbox" id="agreements" required />
                            <label draggable="false" for="agreements" >I've read and accepted the pivacy policy of Webinify</label>

                        </div>
                        <div className="buttons">
                            <button type="submit" onClick={this.registrationHandler} className="register">Register</button>
                            <hr />
                            <button type="link" onClick={() => NavigatorTool.redirect(LoginController._PATH)} className="login">Login</button>

                        </div>
                    </div>
                </div>



            </div>,
            extraActions: null,
        })

    }

}