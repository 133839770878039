import React from "react";
import DashboardController from "../Common/Dashboard/DashboardController";
import AuthController from "../Common/Security/AuthController";



export default class HomeController extends DashboardController {
    constructor(props) {
        super(props)


        this.state = {
            loggedIn: false
        }
    }


    componentDidMount() {
        super.componentDidMount();
        this.checkLoginStatus();

    }

    async checkLoginStatus() {
        const isLoggedIn = await AuthController.isLoggedIn();
        if (!isLoggedIn) {
            window.location.href = '/auth/login';
        }

        this.setState({ loggedIn: isLoggedIn });
    }


    // render the page if authentified only
    renderAuth() {
        return <div className="home-controller">
            Render profile
        </div>;

    }


    render() {

        return super.render({
            dashboardContent: this.renderAuth()
        });


    }

}