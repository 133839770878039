import React from "react";
import AuthController from "../Common/Security/AuthController";
import AppLogo from "../Common/Widget/Logo/AppLogo";
import "./../Assets/Page/LoginController.scss";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import AuthApi from "../Common/Api/AuthApi";
import WebinifyApp from "../Common/Model/WebinifyApp";
import SingleSignOnController from "../Common/Controller/SSO/V1/IdentityProvider/SingleSignOnController";

import NavigatorTool from "../Common/Utility/NavigatorTool";
import RegisterController from "./RegisterController";
import AnonymousController from "../Common/Controller/AnonymousController";

export default class LoginController extends AnonymousController {
    static _PATH = "/auth/login";
    constructor(props) {
        super(props);




        this.state = {
            loggedIn: false,
            showPassword: false,
            email: null,
            password: null,
        }

        this.loginHandler = this.loginHandler.bind(this);
    }


    componentDidMount() {
        this.checkLoginStatus();

    }


    async checkLoginStatus() {
        // Check if is logged in
        const isLoggedIn = await AuthController.isLoggedIn();

        let url = new URL(window.location.href);


        // in case of thre is a return and im logged in already
        if (isLoggedIn && url.searchParams.has("return_to")) {
            let authTokens = await AuthApi.refresh(); // lets refresh to make sure

            if (authTokens) {

                const apps = await WebinifyApp.getAllApps();
                let app = apps.find(app => app.domain === url.searchParams.get("return_to"));

                if (app) {

                    let currentUrl = new URL(window.location.href);
                    currentUrl.pathname = SingleSignOnController._PATH;
                    currentUrl.searchParams.set("return_to", url.searchParams.get("return_to"));

                    window.location.href = currentUrl.href;

                    // // constuire l'URL avec token
                    // let url = new URL(app.getUrl());
                    // url.searchParams.set("token", authTokens.token);
                    // url.searchParams.set("refresh_token", authTokens.refreshToken);
                    // url.searchParams.set('refresh_token_expiration', authTokens.refreshTokenExpiration);
                    // url.searchParams.set('from', REACT_APP_NAME);
                    // url.pathname = "/auth/sso/v1/auhorize";

                    // window.location.href = url.href;
                } else {
                    window.location.href = "/";
                }

            } else {
                window.location.href = "/";
            }

        } else if (isLoggedIn) {
            window.location.href = "/";
        }


        //this.setState({loggedIn: isLoggedIn});
    }

    togglePasswordDisplay = () => {
        this.setState({ showPassword: !this.state.showPassword });
    }




    _onEmailKeyUp = (e) => {
        let email = e.target.value;
        this.setState({ email: email })
    }

    _onPasswordKeyUp = (e) => {
        let password = e.target.value;
        this.setState({ password: password })
    }



    _login = async () => {
        await AuthApi.login(this.state.email, this.state.password);
        window.location.href = "/";
    }

    _loginAnApp = async (appName) => {
        await AuthApi.login(this.state.email, this.state.password);

        let currentUrl = new URL(window.location.href);
        currentUrl.pathname = SingleSignOnController._PATH;
        currentUrl.searchParams.set("return_to", appName);

        window.location.href = currentUrl.href;

        // const apps = await WebinifyApp.getAllApps();
        // let app = apps.find(app => app.domain === appName);

        // // constuire l'URL avec token
        // let url = new URL(app.getUrl());
        // url.searchParams.set("token", authTokens.token);
        // url.searchParams.set("refresh_token", authTokens.refreshToken);
        // url.searchParams.set('refresh_token_expiration', authTokens.refreshTokenExpiration);
        // url.searchParams.set('from', REACT_APP_NAME);
        // url.pathname = "/auth/sso/v1/auhorize";

        // window.location.href = url.href;


    }

    loginHandler = async () => {
        const currentUrl = new URL(window.location.href);

        if (currentUrl.searchParams.has("return_to") && SingleSignOnController.isAppToReturnIDP(currentUrl.searchParams.get("return_to")) === false) {
            // if we have a return to
            await this._loginAnApp(currentUrl.searchParams.get("return_to"));
        } else {
            /// if we dont have a return_to parameter in the url
            await this._login();
        }
    }

    render() {
        // render the login page 

        let data = <div className="login-controller">
            <div className="form">
                <AppLogo></AppLogo>

                <div className="inputs">
                    <input onKeyUp={this._onEmailKeyUp} autoComplete="true" className="email" type="email" placeholder="Email" />
                    <div className="password-fields">
                        <input onKeyUp={this._onPasswordKeyUp} autoComplete="true" className="password" type={this.state.showPassword ? "text" : "password"} placeholder="Password" />
                        <button onClick={this.togglePasswordDisplay} className="togglePasswordDisplay" data-active={this.state.showPassword}>
                            {this.state.showPassword === false ? <AiFillEye /> : <AiFillEyeInvisible />}
                        </button>

                    </div>
                    <div className="usefull-links">
                        <p className="">Forgot password ? <a href="/auth/forgot-password">recover</a></p>

                    </div>
                    <div className="buttons">
                        <button type="submit" onClick={this.loginHandler} className="login">Login</button>
                        <hr />
                        <button type="link" onClick={() => NavigatorTool.redirect(RegisterController._PATH)} className="register">Register</button>

                    </div>
                </div>
            </div>
        </div>;

        return super.render({
            inviewToRender: data,
            extraActions: null,
        })
    }

}