import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginController from './Controller/LoginController';
import RegisterController from './Controller/RegisterController';
import HomeController from './Controller/HomeController';

import SingleSignOnController from './Common/Controller/SSO/V1/IdentityProvider/SingleSignOnController';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeController />} />

        <Route path={LoginController._PATH} element={<LoginController />} />
        <Route path={RegisterController._PATH} element={<RegisterController />} />

        <Route path={SingleSignOnController._PATH} element={<SingleSignOnController />} />

      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
