import Constants from "../Constants";


export default class ApiPrefix {
    static apiDomains = [
        {
            API: 'PUBLIC',
            dev: "https://public-api.webinify.dev",
            prod: "https://public-api.webinify.com",
            local: "http://public-api.webinify.local:7005",
            version: null
        },
        {
            API: 'AUTH',
            dev: "https://auth-api.webinify.dev",
            prod: "https://auth-api.webinify.com",
            // local: "http://auth-api.webinify.local:7015",
            local: "https://auth-api.webinify.dev",
            version: null
        },
        {
            API: 'DOMAIN',
            dev: "https://domains-api.webinify.dev",
            prod: "https://domains-api.webinify.com",
            local: "http://domains-api.webinify.local:7025",
            version: 1
        },
        {
            API: 'SEARCH',
            dev: "https://search-api.webinify.dev",
            prod: "https://search-api.webinify.com",
            local: "http://search-api.webinify.local:7035",
            version: 1
        }
    ];


    /**
     * 
     * @param {String} endpoint DOMAIN, AUTH, PUBLIC, etc..
     * @param {Integer} version 1 = V1
     * @returns {String}
     */
    static getByEnv(endpoint = 'DOMAIN', version = null) {
        const apiEndpoint = ApiPrefix.apiDomains.find(k => k.API === endpoint);
        const _version = version === false ? null : version ?? apiEndpoint.version;
        const apiEndpointUrl = apiEndpoint[Constants.AppEnv] + (_version != null ? `/v${_version}` : "");

        return apiEndpointUrl;
    }
}